const en = {
    common: {
        ok: "OK!",
        cancel: "Cancel",
        back: "Back",
        estimate: "Estimate",
        search: "Search",
        reset: "Reset",
        skip: "Skip",
        next: "Next",
        previous: "Previous",
        finish: "Finish",
        loading: "Loading...",
        yes: "Yes",
        no: "No",
    },
    units: {
        day: "d",
        speed: "Speed",
        pace: "Pace",
        distance: "Distance",
        time: "Time",
        totalTime: "Total time",
        icon: "Icon",
        elevationGain: "Ele. POS",
        elevationLoss: "Ele. NEG",
        elevationAvg: "Alt. AVG",
        elevationMax: "Alt. MAX",
        elevationMin: "Alt. MIN",
        temperature: "Temperature",
        precipitation: "Rain",
        windSpeed: "Wind",
        windDirection: "Wind direction",
        effort: "Effort percentage",
        pace100: "Pace",
        "percent-pace": "% pace",
        "percent-mas": "% MAS",
        mas: "MAS",
        kilometer: {
            none: " km",
            zero: "%{count} km",
            one: "%{count} km",
            other: "%{count} km",
        },
        meter: {
            none: " m",
            zero: "%{count} m",
            one: "%{count} m",
            other: "%{count} m",
        },
        mile: {
            none: " mi",
            zero: "%{count} mi",
            one: "%{count} mi",
            other: "%{count} mi",
        },
        yard: {
            none: " y",
            zero: "%{count} y",
            one: "%{count} y",
            other: "%{count} y",
        },
    },
    segmented: {
        timeObjective: "Keep the average",
        distanceObjective: "Adapt the pace",
    },
    toast: {
        distanceObjective: "When the race has difficulties, such as wind or elevation, the chosen pace will be adapted to allow you to finish the race.",
        timeObjective: "With this mode, the chosen pace will the average speed, regardless of the difficulties of the race. You will be able to perform to surpass the elements.",
    },
    tourGuide: {
        masInput: "Enter your speed or MAS",
        unit: "Change the unit of measurement (metric or imperial)",
        effortCursor: "Vary your effort (from 50% to 150%)",
        distanceInput: "Enter a custom distance to view the time based on your pace",
        timeInput: "Enter a desired time: the pace will be calculated automatically based on the distance entered",
        raceTime: "This time is based on Pacevisor algorithms using your current pace and the profile of the race. It can be refined in the race analysis screen by pressing on the event.",
        raceTimeSmall: "The time (%{time}) is based on Pacevisor algorithms using your current pace, race profile, weather. Refine your race profile to get a more accurate estimate.",
    },
    sports: {
        title: "Sports",
        run: "Run",
        bike: "Bike",
        swim: "Swim",
        transition: "Transition",
    },
    errors: {
        invalidEmail: "Invalid email address.",
    },
    errorScreen: {
        title: "Something went wrong!",
        friendlySubtitle: "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
        reset: "RESET APP",
    },
    emptyStateComponent: {
        generic: {
            heading: "So empty... so sad",
            content: "No data found yet. Try clicking the button to refresh or reload the app.",
            button: "Let's try this again",
        },
    },
    shop: {
        title: "Shop",
        description: "The fast, durable and recyclable bottle.",
    },
    runScreen: {
        title: "Home",
        seeAll: "See all",
        favorites: "My favorites",
        removeFavoriteTitle: "Remove from favorites",
        removeFavoriteDescription: "Do you want to remove this race from your favorites?",
        nearBy: "Near me",
        categories: "Your perfect format",
        races: "Races",
        needLocationTitle: "Using your location",
        needLocationDescription: "Your location is used to find races around the location. This information is not retained after you close the app. Please stay assured that we do not store any location information outside of the device.",
        geolocationTitle: "Find races near me",
        geolocationDescription: "Click here to activate geolocation and find races near me.",
        geolocationButton: "Go",
    },
    bikeScreen: {},
    swimScreen: {},
    profileScreen: {
        title: "Profile",
        localCourses: "My courses",
    },
    category: {
        "seo-title": "%{category} Calendar %{year} in France",
        "5k": "5 km",
        "10k": "10 km",
        halfMarathon: "Half-marathon",
        marathon: "Marathon",
        triathlon: "Triathlon",
        run: "Running",
        bike: "Bike",
        trail: "Trail",
        swim: "Swim",
        "seo-description": "Discover upcoming races in %{name}, offering a diverse selection of races suitable for all abilities. Full list of upcoming races in %{year} - %{yearAfter}.",
    },
    trainingScreen: {
        title: "Training",
        myDistances: "My distances",
        addDistanceButton: "Add",
        addDistance: "Add a distance",
        myTools: "My tools",
    },
    racesScreen: {
        title: "Races library",
        addRace: "Is your race there? Add it now 📝",
        editRace: "Add it now 📝",
        cardTitle: "Want to find races?",
        cardDescription: "Pacevisor offers you analyzes to better prepare you. They are based on your pace and the profile of the race.",
        cardButton: "Find races",
        distance: {
            zero: "Distance",
            one: "Distance: %{count} km",
            other: "Distance: %{count} km",
        },
        location: "Location",
    },
    raceScreen: {
        title: "Race",
        "seo-title": "%{name} %{year} - %{type} of %{distance} km - %{place} (%{departmentCode})",
        "seo-description": "Discover the course for the %{name} race, at %{place} (%{departmentCode}) for %{distance} km long. It will take place on %{date}. You can download GPX trace file and estimate your race time based on your speed (km/h) or your pace (min/km).",
        "seo-description-without-date": "Discover the course for the %{name} race, at %{place} (%{departmentCode}) for %{distance} km long. You can download GPX trace file and estimate your race time based on your speed (km/h) or your pace (min/km).",
        edit: "Saw a mistake? Suggest an edit.",
        mainInfo: "Information about race",
        climberNote: "Climb ability",
        descenderNote: "Ability to descend",
        ability1: "Hard",
        ability2: "Average",
        ability3: "Fair",
        ability4: "Good",
        ability5: "Very good",
        elevation: "%{value} m",
        pace: "%{value} %{unit}",
        weight: "Weight (kg)",
        seeWebsite: "Go to website",
        profile: "Race profile",
        graphSubtitle: "Hold to display a segment dynamically on the graph and on the map",
        paceTitle: "Pace",
        completeAnalysis: "See the full analysis on ",
        gpxFile: "Download GPX file",
        orDownload: "or download app",
        seeMore: "See all the roadbook",
        roadbookExplanation: "The roadbook summarizes the key information of the race. It is personalized according to your race profile, the weather and the route.",
        forecast: "Weather D day",
        waypoint: "Waypoint",
        percentAngleBadge: "+ %{value}%",
        noDataTitle: "There's like a hole in the road...",
        noDataDescription: "No GPX, no analysis... Basically, we're lost like a runner without shoes.",
        predict: {
            edit: "Simulate your results",
            mode: "Pace calculation",
            advanced: "Advanced settings",
            totalTime: "Estimated race time",
            mas: "Use my MAS",
        },
    },
    editRaceScreen: {
        title: "Edit my course",
        name: "Course name",
        placeholder: "Ex: my Sunday race",
        add: "Add a course",
        removeTitle: "Delete training",
        removeDescription: "Are you sure you want to delete this race from your phone?",
    },
    mapScreen: {
        title: "Map races",
    },
    storybook: {
        placeholder: "Placeholder",
        field: "Field",
    },
    pricing: {
        title: "Pricing",
    },
    landing: {
        "seo-title": "Running time and pace simulator | Free app Pacevisor",
        "seo-description": "5 km, 10 km, half marathon... Pacevisor application allows you to convert and predict your running times. You can adjust distance, speed (km/h) and even pace (min/km) and everything updates automatically. An estimate of your pace is visible over other distances, and in other sports such as cycling or swimming.",
        home: "Home",
        organizer: "Organizer",
        openInApp: "See my stats in app",
        fullTitle: "Pacevisor, your race time converter",
        download: "Download app",
        title: "Pacevisor",
        subtitle: "Goal. Conversion. Performance.",
        description: `For versatile athletes##Whether you're into running, swimming, or cycling, our app adapts to all distances, including triathlons. View your performance in miles or yards, a valuable asset for your international training.

Precision pace modulation##Adjust your pace in real time with our interactive slider. Whether for a specific training session or to estimate your times over different distances, our pace modulation feature allows you to instantly visualize the impact of each adjustment. For example, find out what 90% of 4:30 min/km over a distance of 3 km represents (that's 15 minutes!).

Dynamic time and speed adaptation##Change the time for a given distance and watch your speed adjust automatically. This feature is ideal for setting race goals or tailoring your training to specific race scenarios.

New for trail runners##Benefit from our advanced segment time calculation feature, tailored to the unique characteristics of trail running. Taking into account your personal climbing and descending abilities, this feature allows you to optimize every stage of your trail route, providing you with detailed and personalized analysis to improve your performance.`,
        racesTitle: "Find your finish line",
        tile1: "Races library",
        tile1Description: `Explore a vast collection of races.
Filter and find races across a variety of distances and elevations to train for and perform at your next competition.`,
        tile2: "Pace prediction",
        tile2Description: "Benefit from Pacevisor's advanced algorithms to predict your pace based on your past performance, race topography, and current goals.",
        tile3: "Time and distance converter",
        tile3Description: 'With the "Training" mode, easily convert time into distance and vice versa with our intuitive tool. Ideal for planning your training and managing your goals.',
        tile4: "Custom roadbook",
        tile4Description: "Get a custom roadbook for each race, detailing your race strategy, refreshment points and planned pace changes.",
        tile5: "Free and ad-free!",
        tile5Description: "Download the app for free and enjoy an ad-free experience. Only organizers pay to add their races to the library. Available on iOS and Android.",
    },
    social: {
        help: "Need help?",
        switchLang: "Version Française ?",
        privacyPolicy: "Privacy policy",
    },
    platform: {
        title: "Plateforme",
        subtitle: "Transformez l'expérience des événements avec Pacevisor",
        intro: `Intégré directement à votre plateforme d'événements, Pacevisor permet aux organisateurs d'enrichir l'expérience de chaque coureur avec des prévisions de course personnalisées.

Pacevisor se fond dans l'interface de votre plateforme pour une expérience utilisateur fluide. Offrez un service supplémentaire qui différencie votre plateforme sur le marché.`,
        addPluginTitle: "J'ajoute le plugin sur ma plateforme",
        queryParamsTitle: "L'intégration du plugin se fait par iframe, où il est possible de customiser l'interface par des paramètres d'URL à ajouter à la base url :",
    },
    new: {
        addRace: "Add a race",
        editRace: "Edit a race",
        "seo-description": "Add a race to Pacevisor to integrate it into the app.",
        dropGpxHere: "Click here to drop your GPX file",
        dontHaveGpx: "I continue without GPX",
        fields: {
            id: "Event identifier",
            active: "Active",
            title: "Title of the event",
            type: "Race type (run, bike, swim)",
            gpxUrl: "GPX file URL (optional)",
            baseSpeed: "Known speed of the participant (unit in kilometers per second, optional)",
            weight: "Weight of the participant (in kg, optional)",
            distance: "Distance of the race",
            countryCode: "Country (optional)",
            departmentCode: "Department (optional)",
            latitude: "Latitude (optional)",
            longitude: "Longitude (optional)",
            place: "Place (optional)",
            email: "Your email",
            url: "URL of the event site, or registration (optional)",
            date: "Date of the event",
            imageUrl: "Image URL (optional)",
            waypoints: "Waypoints (name, emoji and distance, optional)",
            waypointTags: "Tags (separated by commas, optional)",
            addWaypoint: "Add",
            removeWaypoint: "Remove",
            tags: "Tags (optional)",
            hideOptional: "Hide optional fields",
            showOptional: "Show more configuration",
        },
        get: "Get",
        submit: "Submit and send",
    },
    ghostScreen: {
        title: "Start race",
        description: "It's your personalized pacer! Like the ghost in Mario Kart, but for your race! Follow your pace from your Pacevisor goals. Turn on notifications.",
        stop: "Stop",
        start: "Start",
        yourGhost: "Goal",
        yourSelf: "Avg.",
        tooFast: "You're %{time} too fast.",
        tooSlow: "You're %{time} too slow.",
        notifTitle: "Km %{segment}, goal %{segmentSpeed}, current %{averageSpeed}",
        notifDescription: "D+ %{elevationGain}, D- %{elevationLoss}, %{percentAngle}%",
    },
    ghost: {
        title: "Custom race pacer",
        description: "Remember the ghost in Mario Kart? Want to see your replay? Launch the activity on Pacevisor and follow the rhythm on your phone and smartwatch.",
        step1: "Open the race and adjust your goals",
        step2: "Start the pacer activity",
        step3: "View information in real time",
        step4: "Receive notifications every kilometer",
    },
    organizer: {
        subtitle: "Improve your participants' experience with Pacevisor, the essential widget for any running, bike or swim event!",
        "seo-title": "Event organizer",
        "seo-description": "Using Pacevisor for your event is possible! Integrate the Pacevisor widget on your website to allow your participants to calculate their running time.",
        intro: "Pacevisor is an interactive tool designed to help runners visualize and plan their race strategy. Once the race has been validated by the Pacevisor team, go to the next step!",
        step: "Step %{step}: %{title}",
        seeRaceTitle: "View how it looks",
        seeRaceDescription: "Once the race has been validated by the Pacevisor team, you can view the race rendering on the application, or on the web.",
        seeRaceButton: "See race",
        shareRaceTitle: "Share on my socials",
        shareRaceDescription: "Share the race on your social networks to help your participants prepare as well as possible and strengthen your community.",
        shareRaceButton: "Copy the link by clicking here",
        addMyRaceTitle: "Add my race",
        addWidgetTitle: "Add the widget to my website",
        addWidgetDescription: "Our widget is easily integrated into your site and offers a precise estimate of running time based on the runner's pace. Copy the code below and paste it into your website source code.",
        congratulationsTitle: "Congratulations!",
        congratulationsDescription: "Your race has been successfully added. Your participants can now view and plan their race strategy. Thank you for them!",
        copyPaste: "Copy and paste the code on your site",
        exampleTitle: "Widget example",
        copied: "Copied!",
        copy: "Copy HTML code",
    },
    calendar: {
        departments: "Nearby departments",
        allRaces: "All races",
        "seo-title": "Races calendar %{year}",
        "seo-description": "Find out upcoming running, cycling or swimming events. Add your race to get an estimate of your running time. All upcoming races are there.",
        "title-department": "Races calendar - %{department}",
        "seo-title-category-department": "%{category} Calendar %{department} %{year}",
        "seo-description-category-department": `Find out upcoming %{category} events in %{department}. Add your race to get an estimate of your running time. All upcoming races are there.`,
        "seo-title-department": "Races calendar %{department} %{year}",
        "seo-description-department": "Find out upcoming running, cycling or swimming events in %{department}. Add your race to get an estimate of your running time. All upcoming races are there.",
    },
    faq: {
        title: "Frequently asked questions",
        content: `How can Pacevisor improve my running performance?
Pacevisor helps you understand and optimize your running pace, allowing for more effective training and competition planning. Feel free to add custom distances with the "+" button under the "Estimation" section to tailor the app to your needs.

How do I convert min/km to km/h for running?
Use our built-in conversion tool to easily turn your pace from min/km to km/h.
For example, 6 min/km will convert to 10 km/h.

What's the best way to use Pacevisor for cycling?
Focus on the km/h conversion feature to monitor and adjust your speed during training.
For example, input 20 km/h to assess your performance on various segments.

How can Pacevisor assist me in swimming?
Use the app to convert and understand your times in min/100m, a key metric for swimmers.
In this sport, distances are always used in meters or yards.

Can I use Pacevisor for triathlons?
Absolutely, Pacevisor is ideal for managing paces in all three triathlon disciplines. Transition times will soon be added to the app.

How do I vary my training intensity with Pacevisor?
The modulation slider lets you adjust your Maximal Aerobic Speed (MAS) from 50% to 150%. It automatically recalculates your paces for different sports.
For example, if your MAS is 12 km/h and you set the slider to 80%, Pacevisor will recalculate your pace to match a MAS of 9.6 km/h. This helps you plan workouts for various intensity levels.

How do I estimate run time for a new race?
Enter your race details in Pacevisor for an accurate race time estimate based on your usual pace. A slider lets you adapt your uphill and downhill ability to refine your time, segment by segment.

How can I plan my training with Pacevisor?
Use the conversion tools to adapt your pace for different types of training, from endurance sessions to sprints.

Is it possible to adjust my pace based on altitude?
Yes, Pacevisor can help you adjust your pace for altitude variations, which are essential in trail running.

How do I integrate event data into Pacevisor?
If you're organizing or participating in an event, you can use Pacevisor to analyze and share specific race profiles.
Visit the Organizer page for more information.

What are the benefits of understanding unit conversions in sports?
Mastering these conversions helps you refine your training strategy and improve your athletic performance.`,
    },
    footer: {
        enterprise: "Enterprise",
        runner: "Runner",
    },
    masTable: {
        "seo-title": "MAS Pace Table Online: Race Calculator Tool, Cooper, 10km, Marathon",
        "seo-description": "Pace chart to easily see the race time for the main events based on your speed (km/h and min/km) over 5 km, half marathon or marathon. Maximum Aerobic Speed (MAS) represents the speed at which the runner reaches his maximum oxygen consumption (VO2max). 100% of MAS represents the speed that one would be able to maintain over a 3000m.",
        title: "MAS: test, calculation and pace table",
        description: "Maximum Aerobic Speed (MAS) represents the speed at which the runner reaches his maximum oxygen consumption (VO2max). 100% of MAS represents the speed that one would be able to maintain over a 3000m. It is a key indicator of endurance performance, because it determines the maximum intensity that an athlete can maintain while making the best use of all the channels.",
        descriptionShort: "MAS represents the speed at which the runner reaches his maximum oxygen consumption (VO2max). This table allows you to see the different pace zones according to your MAS.",
        cta: "Go to table at bottom",
        kmh: "Speed (km/h)",
        "min-km": "Pace (min/km)",
        input: "Enter your MAS",
        startSpeed: "Start speed",
        endSpeed: "End speed",
        range: "Range",
        moreOptions: "More options",
        legend: {
            easy: "Easy zone",
            medium: "Medium zone",
            hard: "Hard zone",
        },
    },
    speedTable: {
        title: "Specific pace table",
        descriptionShort: "Adjust your times and paces over specific distances (100m, 400m, 5km, etc.). An ideal tool for planning sessions tailored to your training goals.",
    },
};
export default en;
